import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/message/List.vue')
  },
  {
    path: '/message/add',
    name: 'messageAdd',
    component: () => import('@/views/message/Add.vue')
  },
  {
    path: '/message/update:id',
    name: 'messageUpdate',
    component: () => import('@/views/message/Add.vue')
  },
  {
    path: '/message/detail:id',
    name: 'messageDetail',
    component: () => import('@/views/message/Detail.vue')
  }
]

export default router
