import Vue from 'vue'
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router'
import { Message } from 'element-ui'
import Layout from '../views/Layout.vue'
import Account from './account'
import Developer from './developer'
import Device from './device'
import Notice from './notice'
import Messages from './message'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/developer',
    children: [
      ...Account,
      ...Developer,
      ...Device,
      ...Notice,
      ...Messages,
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/monitor/List.vue')
      },
      {
        path: '/document',
        name: 'document',
        component: () => import('@/views/document/Index.vue')
      },
      {
        path: '/404',
        component: () => import('@/views/404.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

// 临时修复路由跳转为当前路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location: RawLocation) {
  return (originalPush.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}
// 临时修复路由跳转为当前路由报错问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location: RawLocation) {
  return (originalReplace.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}

// 路由错误处理
router.beforeEach((to, from, next) => {
  if ((to.meta.requireLogin !== undefined && !to.meta.requireLogin) || window.localStorage.getItem('token')) {
    // 判断是否需要登录或者是否已经登录,不需要登录或者已经登录正常跳转
    if (to.matched.length === 0) {
      // 跳转404
      next('/404')
    } else {
      next() // 如果匹配到正确跳转
    }
  } else {
    Message.error('您还未登录或者登录已过期,请先登录!')
    // 需要登录且未登录,跳转登录页面
    next('/login')
  }
})
export default router
