
export interface Apis {
  /** 新增文档 */
  insertDocument: string;
  /** 查询文档列表 */
  selectDocumentByList: string;
  /** 修改文档 */
  updateDocument: string;
  /** 删除文档 */
  deleteDocument: string;
  /** 文档排序 */
  updateDocumentSort: string;
}

export const apis: Apis = {
  insertDocument: '/api/document/insertDocument',
  selectDocumentByList: '/api/document/selectDocumentByList',
  updateDocument: '/api/document/updateDocument',
  deleteDocument: '/api/document/deleteDocument',
  updateDocumentSort: '/api/document/updateDocumentSort'
}
