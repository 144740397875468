// 菜单配置
const menuList = [
  {
    name: '开发者管理',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/developer'
  }, {
    name: '文档管理',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/document'
  }, {
    name: '账户管理',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/account'
  },
  {
    name: '设备管理',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/device'
  },
  {
    name: '接口监控',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/monitor'
  },
  {
    name: '公告',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/notice'
  },
  {
    name: '消息',
    icon: '#iconicon_tabtoor_left_shebeiliebiao',
    url: '/message'
  }
]
export default menuList
