
export interface Apis {
  /** 新增 */
  insertDevice: string;
  /** 设备列表 */
  getDeviceByList: string;
  /** 导入 */
  importDevice: string;
  /** 分配 */
  batchAllocateDevice: string;
  /** 分页列表 */
  getDeviceByPage: string;
  /** 回收 */
  batchRecycle: string;
  /** 设备类型列表 */
  selectDeviceTypeByList: string;
  /** 设备历史数据 */
  selectDeviceDataPage: string;
}

export const apis: Apis = {
  insertDevice: '/api/device/insertDevice',
  getDeviceByList: '/api/device/getDeviceByList',
  getDeviceByPage: '/api/device/getDeviceByPage',
  importDevice: '/api/device/importDevice',
  batchAllocateDevice: '/api/device/batchAllocateDevice',
  batchRecycle: '/api/device/batchRecycle',
  selectDeviceTypeByList: '/api/device/selectDeviceTypeByList',
  selectDeviceDataPage: '/api/device/selectDeviceDataPage'
}
