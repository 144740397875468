import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/device/List.vue')
  },
  {
    path: '/device/historyData:deviceId',
    name: 'historyData',
    component: () => import('@/views/device/HistoryData.vue')
  },
  {
    path: '/device/add',
    name: 'addDevice',
    component: () => import('@/views/device/Add.vue')
  }
]

export default router
