import { apis as users, Apis as userApi } from '@/api/users'
import { apis as common, Apis as commonApi } from '@/api/common'
import { apis as developer, Apis as developerApi } from '@/api/developer'
import { apis as document, Apis as documentApi } from '@/api/document'
import { apis as device, Apis as deviceApi } from '@/api/device'
import { apis as account, Apis as accountApi } from '@/api/account'
import { apis as monitor, Apis as monitorApi } from '@/api/monitor'
import { apis as message, Apis as messageApi } from '@/api/message'

export interface Apis {
  users: userApi;
  common: commonApi;
  developer: developerApi;
  document: documentApi;
  device: deviceApi;
  account: accountApi;
  monitor: monitorApi;
  message: messageApi;
}

export const apis: Apis = {
  users,
  common,
  developer,
  document,
  device,
  account,
  monitor,
  message
}
