export interface Apis {
  // 七牛上传地址
  uploadQiniup: string;
  getRegion: string;
  upload: string;
  uploadToken: string;
  /** 数据字典 */
  selectDicByList: string;
}

export const apis: Apis = {
  uploadQiniup: 'http://upload.qiniup.com/',
  getRegion: '/user/address',
  upload: '/user/file/upload',
  uploadToken: '/api/qiniu/getToken',
  selectDicByList: '/api/dic/selectDicByList'
}
