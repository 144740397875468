
export interface Apis {
  /** 新增消息公告 */
  insertMessage: string;
  /** 分页查询 */
  selectMessageByPage: string;
  /** 详情 */
  selectMessageByMessageId: string;
  /** 发布\下架消息公告 */
  releaseMessage: string;
  /** 查询列表 */
  selectMineMessageByPage: string;
  /** 读取消息 */
  readMessage: string;
  /** 编辑 */
  updateMessage: string;
  /** 删除 */
  deleteMessage: string;
}

export const apis: Apis = {
  insertMessage: '/api/message/insertMessage',
  selectMessageByPage: '/api/message/selectMessageByPage',
  selectMessageByMessageId: '/api/message/selectMessageByMessageId',
  releaseMessage: '/api/message/releaseMessage',
  selectMineMessageByPage: '/api/message/selectMineMessageByPage',
  readMessage: '/api/message/readMessage',
  updateMessage: '/api/message/updateMessage',
  deleteMessage: '/api/message/deleteMessage'
}
