import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../element.config'
import axios from '@/utils/ajax'
import { apis } from '@/api'
import 'vue-class-component/hooks'
import Component from 'vue-class-component'

// 公共样式更改
import '@/assets/scss/reset.scss'
import '@/assets/icon/iconfont.js'

// 公共组件调用
import GlobalPagination from './components/pagination/GlobalPagination.vue'
import GlobalDetailHeader from './components/GlobalDetailHeader.vue'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

Vue.component('GlobalPagination', GlobalPagination)
Vue.component('GlobalDetailHeader', GlobalDetailHeader)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$apis = apis
Vue.prototype.$event = new Vue()
Vue.prototype.$config = process.env
console.log('配置信息：', process.env)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
