// 接口名定义
export interface Apis {
  login: string;
  logout: string;
  getVerifyCode: string;
}

export const apis: Apis = {
  login: '/api/admin/login',
  logout: '/api/admin/logout',
  getVerifyCode: '/api/apiUser/getVerifyCode'
}
