import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/developer',
    name: 'developer',
    component: () => import('@/views/developer/List.vue')
  }
]

export default router
