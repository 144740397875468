import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/account',
    name: 'accountList',
    component: () => import('@/views/account/List.vue')
  }

]

export default router
