












































import { Component, Vue } from 'vue-property-decorator'
import menuList from './menu'
@Component
export default class Menu extends Vue {
  private menuList: object[] = menuList
}
