export interface Apis {
  /** 新增账户 */
  insertAdminUser: string;
  /** 修改账户 */
  updateAdminUser: string;
  /** 禁用账户 */
  deleteAdminUser: string;
  /** 账户详情 */
  selectAdminUserByUserId: string;
  /** 分页查询账户列表 */
  selectAdminUserByPage: string;
  /** 重置账户密码 */
  resetPassword: string;
  /* 修改当前账户密码  */
  updatePassword: string;
}

export const apis: Apis = {
  insertAdminUser: '/api/admin/insertAdminUser',
  updateAdminUser: '/api/admin/updateAdminUser',
  deleteAdminUser: '/api/admin/deleteAdminUser',
  selectAdminUserByUserId: '/api/admin/selectAdminUserByUserId',
  selectAdminUserByPage: '/api/admin/selectAdminUserByPage',
  resetPassword: '/api/admin/resetPassword',
  updatePassword: '/api/admin/updatePassword'

}
