
export interface Apis {
  selectApiUserByPage: string;
  deleteApiUser: string;
  /** 查询开发者列表 */
  selectApiUserList: string;
}

export const apis: Apis = {
  selectApiUserByPage: '/api/apiUser/selectApiUserByPage',
  deleteApiUser: '/api/apiUser/deleteApiUser',
  selectApiUserList: '/api/apiUser/selectApiUserList'
}
