import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/notice/List.vue')
  },
  {
    path: '/notice/add',
    name: 'noticeAdd',
    component: () => import('@/views/notice/Add.vue')
  },
  {
    path: '/notice/update:id',
    name: 'noticeUpdate',
    component: () => import('@/views/notice/Add.vue')
  }
]

export default router
