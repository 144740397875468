
















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'GlobalPagination'
})
export default class GlobalPagination extends Vue {
    @Prop({ default: 0 }) private total!: number
    @Prop({ default: 1 }) private currentPage!: number
    @Prop({ default: 10 }) private pageSize!: number
    @Prop({ default: () => [5, 10, 20, 50, 100, 200, 500] }) private pageSizes!: number[]
    @Prop({ default: 'total, sizes, prev, pager, next, jumper' }) private pageLayout!: string

    handleCurrentChange (value: number) {
      this.$emit('update:current-page', value)
      this.$emit('current-change', value)
    }

    handleSizeChange (value: number) {
      this.$emit('update:page-size', value)
      this.$emit('size-change', value)
    }
}

