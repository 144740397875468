import Vue from 'vue'
import Vuex from 'vuex'
// import { UserState } from './modules/user'

Vue.use(Vuex)

export interface RootState {
  keepAlive: string[];
}

export default new Vuex.Store<RootState>({
  state: {
    keepAlive: []
  },
  mutations: {
    addKeepAlive (state: RootState, data: string) {
      if (!state.keepAlive.includes(data)) {
        state.keepAlive.push(data)
      }
    },
    removeKeepAlive (state: RootState, data: string) {
      const index = state.keepAlive.findIndex(item => item === data)
      state.keepAlive.splice(index, 1)
    }
  }
})

// // Declare empty store first, dynamically register all modules later.
// export default new Vuex.Store<RootState>({})
